import React, { Component } from 'react';

export class Ourprojects extends Component {
    static displayName = Ourprojects.name;
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'all',
            projects: [
                { id: 1, title: 'Project 1', description: 'Description for Project 1', category: 'Web', imgSrc: 'https://via.placeholder.com/400x150' },
                { id: 2, title: 'Project 2', description: 'Description for Project 2', category: 'Mobile', imgSrc: 'https://via.placeholder.com/400x150' },
                { id: 3, title: 'Project 3', description: 'Description for Project 3', category: 'Web', imgSrc: 'https://via.placeholder.com/400x150' },
                { id: 4, title: 'Project 4', description: 'Description for Project 4', category: 'Design', imgSrc: 'https://via.placeholder.com/400x150' },
                { id: 5, title: 'Project 5', description: 'Description for Project 5', category: 'Mobile', imgSrc: 'https://via.placeholder.com/400x150' },
                { id: 6, title: 'Project 6', description: 'Description for Project 6', category: 'Design', imgSrc: 'https://via.placeholder.com/400x150' }
            ],
            categories: ['all', 'Web', 'Mobile', 'Design']
        };
    }

    handleCategoryChange = (category) => {
        this.setState({ selectedCategory: category });
    }

   

    render() {
        const { selectedCategory, projects, categories } = this.state;
        const filteredProjects = selectedCategory === 'all' ? projects : projects.filter(project => project.category === selectedCategory);

        return (
            <>

                <div className="container project-container">
                    <h2 className="section-heading">Our Projects</h2>
                    <div className="category-buttons">
                        {categories.map(category => (
                            <button
                                key={category}
                                className={`btn ${selectedCategory === category ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={() => this.handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-deck">
                                {filteredProjects.map(project => (
                                    <div key={project.id} className="card project-card">
                                        <img src={project.imgSrc} alt={project.title} className="card-img-top" />
                                        <div className="card-body">
                                            <h5 className="card-title">{project.title}</h5>
                                            <p className="card-text">{project.description}</p>
                                            <a href="#" className="btn btn-custom">Learn More</a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>



                </>
        );
    }
}
