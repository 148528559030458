import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export class Clients extends Component {
    static displayName = Clients.name;

    componentDidMount() {
        // Ensure jQuery is available globally
        const $ = window.$;

        // Initialize the Product Carousel
        $('.product-carousel').owlCarousel({
            autoplay: true,
            smartSpeed: 1000,
            margin: 25,
            loop: true,
            center: true,
            dots: false,
            nav: true,
            navText: [
                '<i class="bi bi-chevron-left"></i>',
                '<i class="bi bi-chevron-right"></i>'
            ],
            responsive: {
                0: { items: 1 },
                576: { items: 1 },
                768: { items: 2 },
                992: { items: 3 }
            }
        });

        // Modal Video Functionality
        let $videoSrc;
        $(document).on('click', '.btn-play', function () {
            $videoSrc = $(this).data("src");
        });

        $('#videoModal').on('shown.bs.modal', function () {
            $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
        });

        $('#videoModal').on('hide.bs.modal', function () {
            $("#video").attr('src', $videoSrc);
        });
    }

    componentWillUnmount() {
        // Clean up Owl Carousel
        const $ = window.$;
        $('.product-carousel').owlCarousel('destroy');
    }

    render() {
        return (
            <>

              
                <div className="container-fluid product py-5 my-5">
                    <div className="container py-5">
                        <div
                            className="section-title text-center mx-auto wow fadeInUp"
                            data-wow-delay="0.1s"
                            style={{ maxWidth: '500px' }}
                        >
                            <p className="fs-5 fw-medium fst-italic text-primary">Our Clients</p>
                           
                        </div>
                        <div className="owl-carousel product-carousel wow fadeInUp" data-wow-delay="0.5s">
                            <a href="#" className="d-block product-item rounded">
                                <img src="assets/img/roproducts/roproduct3.jpeg" alt="Green Tea" />
                                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                                    <h4 className="text-primary">Green Tea</h4>
                                    <span className="text-body">Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum</span>
                                </div>
                            </a>
                            <a href="#" className="d-block product-item rounded">
                                <img src="assets/img/roproducts/roproduct3.jpeg" alt="Black Tea" />
                                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                                    <h4 className="text-primary">Black Tea</h4>
                                    <span className="text-body">Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum</span>
                                </div>
                            </a>
                            <a href="#" className="d-block product-item rounded">
                                <img src="assets/img/roproducts/roproduct3.jpeg" alt="Spiced Tea" />
                                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                                    <h4 className="text-primary">Spiced Tea</h4>
                                    <span className="text-body">Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum</span>
                                </div>
                            </a>
                            <a href="#" className="d-block product-item rounded">
                                <img src="assets/img/roproducts/roproduct3.jpeg" alt="Organic Tea" />
                                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                                    <h4 className="text-primary">Organic Tea</h4>
                                    <span className="text-body">Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum</span>
                                </div>
                            </a>
                        </div>
                        {/* Modal Video */}
                        <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="videoModalLabel">Video</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <iframe id="video" width="100%" height="315" src="" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}
