import React, { Component } from 'react';

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            isSubmitting: false,
            submissionStatus: '',
            errors: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        };
    }

    validateForm = () => {
        const { name, email, subject, message } = this.state;
        let isValid = true;
        let errors = {
            name: '',
            email: '',
            subject: '',
            message: ''
        };

        if (!name) {
            errors.name = 'Name is required.';
            isValid = false;
        }
        if (!email) {
            errors.email = 'Email is required.';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid.';
            isValid = false;
        }
        if (!subject) {
            errors.subject = 'Subject is required.';
            isValid = false;
        }
        if (!message) {
            errors.message = 'Message is required.';
            isValid = false;
        }

        this.setState({ errors });
        return isValid;
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (!this.validateForm()) {
            return;
        }

        this.setState({ isSubmitting: true });

        const { name, email, subject, message } = this.state;

        try {
            const response = await fetch('http://api.mstenterprises.in/contact_api/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, subject, message }),
            });

            if (response.ok) {
                this.setState({ submissionStatus: 'Message sent successfully!', isSubmitting: false });
            } else {
                this.setState({ submissionStatus: 'Failed to send message.', isSubmitting: false });
            }
        } catch (error) {
            this.setState({ submissionStatus: 'Error occurred.', isSubmitting: false });
        }
    }

    render() {
        const { name, email, subject, message, isSubmitting, submissionStatus, errors } = this.state;

        return (
            <>
                <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container text-center py-5">
                        <h1 className="display-2 text-dark mb-4 animated slideInDown">Contact Us</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                <li className="breadcrumb-item text-dark" aria-current="page">Contact</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {/* Contact Start */}
                <div className="container-xxl contact py-5">
                    <div className="container">
                        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                            <p className="fs-5 fw-medium fst-italic text-primary">Contact Us</p>
                            <h1 className="display-6">If You Have Any Query, Please Contact Us</h1>
                        </div>
                        <div className="row g-5 mb-5">
                            {/* Contact info section */}
                            {/* ... your contact info code ... */}
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <h3 className="mb-4">Contact Form</h3>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                    id="name"
                                                    placeholder="Your Name"
                                                    value={name}
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="name">Your Name</label>
                                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    placeholder="Your Email"
                                                    value={email}
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="email">Your Email</label>
                                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                                                    id="subject"
                                                    placeholder="Subject"
                                                    value={subject}
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="subject">Subject</label>
                                                {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea
                                                    className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                                    placeholder="Leave a message here"
                                                    id="message"
                                                    style={{ height: "120px" }}
                                                    value={message}
                                                    onChange={this.handleChange}
                                                ></textarea>
                                                <label htmlFor="message">Message</label>
                                                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button
                                                className="btn btn-primary rounded-pill py-3 px-5"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Sending...' : 'Send Message'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {submissionStatus && <p>{submissionStatus}</p>}
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <iframe
                                        className="w-100 rounded"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14013.233518922938!2d77.0844315!3d28.590524149999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b3f9902dce7%3A0xdf51d27e693abbea!2sPalam%2C%20Delhi!5e0!3m2!1sen!2sin!4v1693417114215!5m2!1sen!2sin"
                                        frameBorder="0"
                                        style={{ height: '100%', minHeight: '300px', border: '0' }}
                                        allowFullScreen
                                        aria-hidden="false"
                                        tabIndex="0"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact End */}
            </>
        );
    }
}
