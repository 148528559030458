import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Water_Filter extends Component {
    static displayName = Water_Filter.name;


    render() {
        return (
            <>
                {/*  <!-- Page Header Start -->
                 */}
                <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container text-center py-5">
                        <h1 className="display-2 text-dark mb-4 animated slideInDown">Water Filter</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>

                                
                                <li className="breadcrumb-item text-dark" aria-current="page"> Water Filter</li>

                            </ol>
                        </nav>
                    </div>
                </div>

                {/*<!-- Page Header End -->
                 */}
                <div className="container">
                    <h1>Transform Your Water with MST Enterprises’ Elite Filter Solutions</h1>
                    <p>At MST Enterprises, we're redefining what it means to drink pure, clean water. Our water filters aren't just products; they're solutions engineered to enhance your everyday life.</p>

                    <h2>Why MST Enterprises’ Water Filters?</h2>
                    <ul>
                        <li><strong>Innovative Filtration:</strong> Our filters utilize advanced technology to tackle contaminants, delivering water that's not only cleaner but also better-tasting. Whether it's removing chlorine, heavy metals, or other impurities, our filters ensure you get the purity you deserve.</li>
                        <li><strong>Tailored to Your Needs:</strong> We offer a range of filtration options designed to meet different needs and preferences. From compact units for small spaces to high-capacity systems for larger households or businesses, we have the right filter for you.</li>
                        <li><strong>Eco-Conscious Design:</strong> Sustainability is at the heart of what we do. Our filters are designed to reduce waste with long-lasting cartridges and eco-friendly materials. Enjoy clean water while making a positive impact on the environment.</li>
                        <li><strong>User-Friendly Experience:</strong> Installation and maintenance are a breeze with our straightforward design. Easy-to-follow instructions and convenient filter replacement systems mean you can enjoy fresh water without the hassle.</li>
                        <li><strong>Exceptional Quality:</strong> Each filter is rigorously tested to ensure it meets our high standards for performance and durability. Trust in our commitment to providing reliable, top-quality water filtration solutions.</li>
                        <li><strong>Customer-Centric Service:</strong> Our dedicated customer support team is here to assist you with any questions or concerns. We’re committed to ensuring your satisfaction and helping you get the most out of your water filter.</li>
                    </ul>

                    <p>Choose MST Enterprises for water filtration solutions that bring purity, convenience, and sustainability to your life. Experience the difference with our state-of-the-art water filters and enjoy the peace of mind that comes with clean, healthy water.</p>

                    <a href="#explore" class="btn btn-primary">Explore our range and find your perfect water filter today!</a>
                </div>

                <div className="container-fluid video" style={{ margintop: "6rem", marginbottom: "6rem" }}>
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-lg-6 py-5 wow fadeIn" data-wow-delay="0.1s">
                                <div className="py-5">
                                    <h1 className="display-6 mb-4">We provide best  <span className="text-white">quality services</span> and <span className="text-white">for clients </span></h1>
                                    {/*<h5 className="fw-normal lh-base fst-italic text-white mb-5">We provide best quality services for clients.</h5>*/}
                                    <div className="row g-4 mb-5">
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Whole Sales</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Solutions</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Consultant</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Confgurations</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="btn btn-light rounded-pill py-3 px-5" href="">Explore More</a>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="h-100 d-flex align-items-center justify-content-center" style={{ minheight: "300px" }}>
                                    <button type="button" className="btn-play" data-bs-toggle="modal"
                                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/*   <!-- Video Start -->
                 */}





                {/* <!-- Video End -->
                 */}

            </>
        );
    }
}
