import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Services extends Component {
    static displayName = Services.name;


    render() {
        return (
            <>
                {/*  <!-- Page Header Start -->
                 */}
                <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container text-center py-5">
                        <h1 className="display-2 text-dark mb-4 animated slideInDown">Reverse Osmosis (RO)<br/> Water Purification Services</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>

                                <li className="breadcrumb-item text-dark" aria-current="page">Services</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div class="container">
                    <h1>Transform Your Water Quality with Our Advanced RO Systems</h1>

                    <p>Our goal at MST Enterprises is to give you the finest drinking water we can. Our Reverse Osmosis (RO) water purifiers use cutting-edge technology to eliminate pollutants and impurities, resulting in extraordinary water purity. Learn about RO's advantages and how our services guarantee that you receive the clean, refreshing water you are entitled to.</p>

                    <h2>Why Choose RO Purification?</h2>
                    <ul>
                        <li><strong>Superior Filtration:</strong> Up to 99% of dissolved solids, including salts, minerals, and dangerous chemicals, are successfully removed by our RO systems using a semi-permeable membrane. As a consequence, the water is impurity-free and pure.</li>
                        <li><strong>Improved Taste and Odor:</strong> Our RO purifiers make your drinking water taste and smell better, making every sip pleasant, by getting rid of heavy metals, chlorine, and other contaminants.</li>
                        <li><strong>Health Advantages:</strong> Drinking clean water promotes general health and wellbeing. By eliminating bacteria, viruses, and other pathogens from your water, our RO systems make sure that it is safe for you and your family to drink.</li>
                    </ul>

                    <h2>Our All-Inclusive RO Services</h2>
                    <ol>
                        <li><strong>Professional Installation:</strong>
                            <ul>
                                <li><strong>Seamless Integration:</strong> Our qualified staff makes sure your RO system is installed accurately and effectively, blending in with the plumbing already in your house or place of business.</li>
                                <li><strong>System Calibration:</strong> We adjust the system to meet your unique requirements for water quality, maximizing performance to provide the best outcomes.</li>
                            </ul>
                        </li>
                        <li><strong>Consistent Upkeep:</strong>
                            <ul>
                                <li><strong>Filter Replacement:</strong> We provide prompt filter and membrane replacement to maintain high performance and keep your RO system operating smoothly.</li>
                                <li><strong>System Cleaning:</strong> To avoid accumulation and preserve your system's performance, we offer comprehensive cleaning as part of our maintenance services.</li>
                            </ul>
                        </li>
                        <li><strong>Round-the-clock Assistance:</strong>
                            <ul>
                                <li><strong>Troubleshooting and Repairs:</strong> From little tweaks to significant repairs, our committed support staff is here to handle any problems that may occur with your RO system.</li>
                                <li><strong>Customer Service:</strong> We are available to respond to your inquiries and offer advice on how to run and maintain your RO system.</li>
                            </ul>
                        </li>
                        <li><strong>Personalized Solutions:</strong>
                            <ul>
                                <li><strong>Custom Filtration Options:</strong> We tailor our RO systems to address your specific water quality concerns, providing solutions that meet your unique needs.</li>
                                <li><strong>Specialized Units:</strong> Whether you need an under-sink unit, countertop model, or whole-house system, we offer a range of options to suit your preferences.</li>
                            </ul>
                        </li>
                        <li><strong>Educational Resources:</strong>
                            <ul>
                                <li><strong>Usage Instructions:</strong> Learn how to get the most out of your RO system with our easy-to-follow guides and tips.</li>
                                <li><strong>Water Safety Information:</strong> Stay informed about water quality and the benefits of RO purification with our educational resources.</li>
                            </ul>
                        </li>
                    </ol>

                    <h2>Why Trust Us?</h2>
                    <p>With years of experience and a commitment to customer satisfaction, MST Enterprises is your trusted partner for high-quality RO water purification. Our team of experts is dedicated to providing you with exceptional service, ensuring you have access to the cleanest and safest water possible.</p>
                </div>



                <div className="container-fluid video" style={{ margintop: "6rem", marginbottom: "6rem" }}>
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-lg-6 py-5 wow fadeIn" data-wow-delay="0.1s">
                                <div className="py-5">
                                    <h1 className="display-6 mb-4">We provide best  <span className="text-white">quality services</span> and <span className="text-white">for clients </span></h1>
                                    {/*<h5 className="fw-normal lh-base fst-italic text-white mb-5">We provide best quality services for clients.</h5>*/}
                                    <div className="row g-4 mb-5">
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Whole Sales</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Solutions</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Consultant</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Confgurations</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="btn btn-light rounded-pill py-3 px-5" href="">Explore More</a>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="h-100 d-flex align-items-center justify-content-center" style={{ minheight: "300px" }}>
                                    <button type="button" className="btn-play" data-bs-toggle="modal"
                                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </>
        );
    }
}
