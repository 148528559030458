import React, { Component } from 'react';


export class Home extends Component {
    static displayName = Home.name;


    render() {
        return (
            <>
                <div className="container-fluid px-0 mb-5">
                    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                 <img className="w-100" src="assets/sliderpics/1.png" alt="Image" style={{ height: "80vh" }} />
                                {/*  <video className="w-100" controls style={{ height: "70vh" }} autoPlay loop>
                                    <source src="assets/videos/vid2.mp4" type="video/mp4" />
                                </video>*/}
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 text-center">
                                                {/*   <p className="fs-4 text-white animated zoomIn">Welcome to <strong className="text-dark">MST Enerprises</strong></p>
                                                <h1 className="display-1 text-dark mb-4 animated zoomIn">Whole Sale of RO Purifier</h1>
                                                <a href="" className="btn btn-light rounded-pill py-3 px-5 animated zoomIn">Explore More</a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="w-100" src="assets/sliderpics/2.png" alt="Image" style={{ height: "80vh" }} />
                               {/*  <video className="w-100" controls style={{ height: "70vh" }} autoPlay loop>
                                    <source src="assets/videos/vid3.mp4" type="video/mp4" />
                                </video>*/}
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                              <div className="col-lg-7 text-center">
                                                {/*  <p className="fs-4 text-white animated zoomIn">Welcome to <strong className="text-dark">MST Enerprises</strong></p>
                                                <h1 className="display-1 text-dark mb-4 animated zoomIn">RO Repair Services</h1>
                                                <a href="" className="btn btn-light rounded-pill py-3 px-5 animated zoomIn">Explore More</a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="carousel-item">
                                <img className="w-100" src="assets/sliderpics/3.png" alt="Image" style={{ height: "80vh" }} />
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 text-center">
                                                {/*  <p className="fs-4 text-white animated zoomIn">Welcome to <strong className="text-dark">MST Enerprises</strong></p>
                                                <h1 className="display-1 text-dark mb-4 animated zoomIn">RO Purifier Configuration</h1>
                                                <a href="" className="btn btn-light rounded-pill py-3 px-5 animated zoomIn">Explore More</a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>


                <div className="container-xxl py-5">
                    <div className="container">
                       
                        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                           
                           <h1 className="display-6">Online Products</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct1.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div className="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Under Sink RO</h4>
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i className="fa fa-arrow-right ms-2"></i></a>
                                                  </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct2.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div className="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Omega Pro Plus</h4>
                                            </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i className="fa fa-arrow-right ms-2"></i></a>
                                        <a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i className="fa fa-cart-plus ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct3.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua X Vista</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct4.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Omega Pro Plus Orange</h4>
                                       
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct5.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua X Vista</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct7.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Organic Series</h4>
                                       
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct8.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua X Fresh</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct9.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua X Space</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct10.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua X Swift</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct11.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Biz</h4>
                                      
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>




                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct12.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua EVA</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct13.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Biz</h4>
                                       
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*{/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}*/}
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct14.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>






                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct15.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Biz</h4>
                                       
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct16.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Fresh</h4>
                                        
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct17.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Benz</h4>
                                   
                                        {/*  {/* <h4 className="text-primary">$19.00</h4>*/} 
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>









                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct18.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Crystal Smart</h4>
                                        
                                         {/* <h4 className="text-primary">$19.00</h4>*/}
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct19.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Fresh</h4>
                                       
                                         {/* <h4 className="text-primary">$19.00</h4>*/}
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="store-item position-relative text-center">
                                    <img className="img-fluid" src="assets/img/roproducts/roproduct20.jpeg" alt=""></img>
                                    <div className="p-4">
                                        <div class="text-center mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                        <h4 className="mb-3">Aqua Fresh</h4>
                                       
                                         {/* <h4 className="text-primary">$19.00</h4>*/}
                                    </div>
                                    <div className="store-overlay">
                                        <a href="" className="btn btn-primary rounded-pill py-2 px-4 m-2">More Detail <i class="fa fa-arrow-right ms-2"></i></a>
                                        {/*<a href="" className="btn btn-dark rounded-pill py-2 px-4 m-2">Add to Cart <i class="fa fa-cart-plus ms-2"></i></a>*/}
                                    </div>
                                </div>
                            </div>



                            

                            <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                <a href="" className="btn btn-primary rounded-pill py-3 px-5">View More Products</a>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="row g-3">
                                    <div className="col-6 text-end">
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn" data-wow-delay="0.1s" src="assets/img/roproducts/roproduct1.jpeg" alt="" />
                                        <img className="img-fluid bg-white w-50 wow fadeIn" data-wow-delay="0.2s" src="assets/img/roproducts/roproduct2.jpeg" alt="" />
                                    </div>
                                    <div className="col-6">
                                        <img className="img-fluid bg-white w-50 mb-3 wow fadeIn" data-wow-delay="0.3s" src="assets/img/roproducts/roproduct3.jpeg" alt="" />
                                        <img className="img-fluid bg-white w-100 wow fadeIn" data-wow-delay="0.4s" src="assets/img/roproducts/roproduct4.jpeg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="section-title">
                                    <p className="fs-5 fw-medium fst-italic text-primary">About Us</p>
                                    <h1 className="display-6">We offer a Whole Sale on RO water Purifiers.</h1>
                                </div>
                                <div className="row g-3 mb-4">


                                </div>
                                <div className="col-sm-12">
                                    <h5>Our business focuses on selling and delivering products through Whole Sale, Supply, and Services.
                                                     </h5>
                                </div>

                                <div className="border-top mb-4"></div>
                                <div className="row g-3">
                                    <div className="col-sm-12">
                                        <h5>We offer solutions to issues related to RO water purifiers.</h5>

                                    </div>
                                    <div className="col-sm-4">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>





            </>
        );
    }
}
