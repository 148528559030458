import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Water_Purifier_Parts extends Component {
    static displayName = Water_Purifier_Parts.name;


    render() {
        return (
            <>
                {/*  <!-- Page Header Start -->
                 */}
                <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container text-center py-5">
                        <h1 className="display-2 text-dark mb-4 animated slideInDown">Water Purifier Parts</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                 
                                <li className="breadcrumb-item text-dark" aria-current="page"> Water Purifier Parts</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {/*<!-- Page Header End -->
                 */}

                <div className="container">
                    <h1>Explore the Key Components of MST Enterprises’ Advanced Water Purifiers</h1>
                    <p>At MST Enterprises, our commitment to providing you with the highest quality water purification solutions is reflected in the precision and innovation of our products. Each water purifier from MST Enterprises is engineered with cutting-edge technology and premium components to ensure your drinking water is pure, safe, and refreshing. Here’s a closer look at the essential parts that make our water purifiers exceptional:</p>

                    <h2>1. Pre-Filter</h2>
                    <p>Our pre-filter is the first line of defense in MST Enterprises’ water purifiers. It effectively captures large particles such as dirt, sand, and rust, preventing these contaminants from reaching the more sensitive filtration stages. This crucial step helps extend the lifespan of the entire purification system.</p>

                    <h2>2. Sediment Filter</h2>
                    <p>The sediment filter in our purifiers is designed to remove finer particulates that contribute to water cloudiness. By filtering out silt, sand, and other suspended solids, it ensures that the water is clear and free from unwanted particles.</p>

                    <h2>3. Activated Carbon Filter</h2>
                    <p>MST Enterprises’ activated carbon filter is engineered to remove chlorine, volatile organic compounds (VOCs), and other chemicals that impact the taste and odor of water. Our high-performance carbon filter enhances both the quality and flavor of your drinking water.</p>

                    <h2>4. Reverse Osmosis (RO) Membrane</h2>
                    <p>At the heart of our water purifiers is the advanced RO membrane. This semi-permeable barrier effectively removes dissolved salts, heavy metals, and other microscopic contaminants, ensuring the highest level of water purity. Our RO technology is designed to deliver exceptional filtration performance.</p>

                    <h2>5. Post-Carbon Filter</h2>
                    <p>The post-carbon filter in our systems acts as a final polishing stage. It removes any residual tastes or odors, ensuring that the purified water is fresh and pleasant. This extra step guarantees that you receive water that meets our rigorous standards for quality.</p>

                    <h2>6. UV Sterilizer</h2>
                    <p>MST Enterprises’ purifiers are equipped with a UV sterilizer that uses ultraviolet light to inactivate harmful microorganisms such as bacteria, viruses, and protozoa. This additional layer of protection ensures that your water is microbiologically safe.</p>

                    <h2>7. Storage Tank</h2>
                    <p>Our storage tank is designed to hold purified water securely, providing a reliable supply of clean water whenever you need it. The tank’s design helps maintain the water’s purity by preventing contamination.</p>

                    <h2>8. Faucet</h2>
                    <p>The faucet provided with MST Enterprises’ water purifiers is designed for convenience and ease of use. It delivers purified water directly to you with a sleek, user-friendly design that complements your kitchen or office space.</p>

                    <h2>9. Water Pump</h2>
                    <p>For models that include it, our water pump ensures optimal water pressure and flow throughout the purification process. It helps maintain consistent performance and efficiency, even in areas with lower water pressure.</p>

                    <h2>10. Flow Restrictor</h2>
                    <p>The flow restrictor in our purifiers carefully manages the rate at which water flows through the RO membrane. This component ensures that the filtration process is both effective and efficient, balancing water production with membrane capacity.</p>

                    <h2>11. Automatic Shutoff Valve</h2>
                    <p>The automatic shutoff valve in our systems is designed to stop the purification process when the storage tank is full. This feature helps prevent overflows and conserves water, contributing to the overall efficiency of the purifier.</p>

                    <h2>12. Drain Valve</h2>
                    <p>Our drain valve effectively expels waste water and contaminants removed during filtration. This helps maintain the purifier’s efficiency and ensures the system continues to operate smoothly.</p>

                </div>


                <div className="container-fluid video" style={{ margintop: "6rem", marginbottom: "6rem" }}>
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-lg-6 py-5 wow fadeIn" data-wow-delay="0.1s">
                                <div className="py-5">
                                    <h1 className="display-6 mb-4">We provide best  <span className="text-white">quality services</span> and <span className="text-white">for clients </span></h1>
                                    {/*<h5 className="fw-normal lh-base fst-italic text-white mb-5">We provide best quality services for clients.</h5>*/}
                                    <div className="row g-4 mb-5">
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Whole Sales</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Solutions</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Consultant</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Confgurations</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="btn btn-light rounded-pill py-3 px-5" href="">Explore More</a>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="h-100 d-flex align-items-center justify-content-center" style={{ minheight: "300px" }}>
                                    <button type="button" className="btn-play" data-bs-toggle="modal"
                                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/*   <!-- Video Start -->
                 */}





                {/* <!-- Video End -->
                 */}

            </>
        );
    }
}
