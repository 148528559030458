import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Ro_water_purifier extends Component {
    static displayName = Ro_water_purifier.name;


    render() {
        return (
            <>
                {/*  <!-- Page Header Start -->
                 */}
                <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container text-center py-5">
                        <h1 className="display-2 text-dark mb-4 animated slideInDown">RO Water Purifier</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>

                                <li className="breadcrumb-item text-dark" aria-current="page">RO Water Purifier</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {/*<!-- Page Header End -->
                 */}
                <div className="container">
                    <p>At MST Enterprises, we are committed to delivering top-notch Reverse Osmosis (RO) water purifiers that ensure your drinking water is pure, safe, and refreshing. Our state-of-the-art purification systems are designed to meet the highest standards of quality, providing you with clean water that’s free from contaminants and impurities.</p>

                    <h2>Why Choose MST Enterprises?</h2>
                    <ul>
                        <li><strong>Innovative Technology:</strong> Our RO purifiers utilize the latest advancements in filtration technology to effectively remove pollutants, bacteria, and harmful chemicals, ensuring you get the cleanest water possible.</li>
                        <li><strong>Sustainable Practices:</strong> We prioritize eco-friendly solutions. Our purifiers are engineered to minimize water wastage and reduce environmental impact, aligning with our commitment to sustainability.</li>
                        <li><strong>Quality Assurance:</strong> At MST Enterprises, we pride ourselves on delivering products that meet stringent quality standards. Each purifier is thoroughly tested to guarantee reliability, durability, and superior performance.</li>
                        <li><strong>Dedicated Customer Support:</strong> Our experienced team is here to assist you every step of the way. From choosing the right purifier to installation and maintenance, we provide comprehensive support to ensure your complete satisfaction.</li>
                    </ul>
                </div>


                <div className="container-fluid video" style={{ margintop: "6rem", marginbottom: "6rem" }}>
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-lg-6 py-5 wow fadeIn" data-wow-delay="0.1s">
                                <div className="py-5">
                                    <h1 className="display-6 mb-4">We provide best  <span className="text-white">quality services</span> and <span className="text-white">for clients </span></h1>
                                    {/*<h5 className="fw-normal lh-base fst-italic text-white mb-5">We provide best quality services for clients.</h5>*/}
                                    <div className="row g-4 mb-5">
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Whole Sales</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Solutions</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Consultant</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 btn-lg-square bg-white text-primary rounded-circle me-3">
                                                    <i className="fa fa-check"></i>
                                                </div>
                                                <span className="text-dark">RO Purifier Confgurations</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="btn btn-light rounded-pill py-3 px-5" href="">Explore More</a>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="h-100 d-flex align-items-center justify-content-center" style={{ minheight: "300px" }}>
                                    <button type="button" className="btn-play" data-bs-toggle="modal"
                                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/*   <!-- Video Start -->
                 */}





                {/* <!-- Video End -->
                 */}

            </>
        );
    }
}
